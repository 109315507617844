<template>
  <van-overlay :show="boo" @click="boo = false">
    <div class="wrapper" @click.stop>
      <div class="rule">
        <span>使用方式</span>
        <ul class="rule-ul">
          <li @click="freeGo">
            付费免费领取
          </li>
          <li @click="$router.push({ name: 'HomeIndex' })">
            拼单附带
          </li>
        </ul>
        <svg-icon
          icon-class="dialog_error"
          @click="boo = false"
          class="tips_error"
        ></svg-icon>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Overlay } from "vant";
import { get, post } from "@get/http";
export default {
  components: {
    vanOverlay: Overlay,
  },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    id: {},
  },
  data() {
    return {
      boo: false,
    };
  },
  methods: {
    async freeGo() {
      let data = await post("/lw.MemberWelfare/orderSignInWelfare", {
        data: {
          id: this.id,
        },
      });
      this.$router.push({
        name: "OrderDetail",
        query: {
          order_id: data,
        },
      });
    },
  },
  created() {
    this.boo = this.show;
  },
  watch: {
    show() {
      this.boo = this.show;
    },
    boo() {
      this.$emit("update:show", this.boo);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.rule {
  width: rem(558);
  height: rem(418);
  background: #fff;
  border-radius: rem(16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  > span {
    color: #222222;
    font-weight: bold;
    font-size: rem(34);
    text-align: center;
    display: block;
    margin-top: rem(40);
  }
  .rule-ul {
    width: rem(478);
    margin: rem(35) auto 0;
    li {
      width: rem(480);
      height: rem(84);
      line-height: rem(84);
      text-align: center;
      color: #ff4848;
      font-size: rem(30);
      border: rem(2) solid #ff4848;
      box-sizing: border-box;
      border-radius: rem(42);
      margin-bottom: rem(40);
    }
  }
}
.tips_error {
  position: absolute;
  width: rem(63);
  height: rem(63);
  bottom: rem(-120);
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
