<template>
  <div class="wrap">
    <template v-if="item.type == 'voucher'">
      <div class="price">
        <div class="couponPrice">
          <span>￥</span>
          <b>{{ item.voucher.vouchertemplate_price }}</b>
        </div>
        <p class="couponSub">
          满{{ item.voucher.vouchertemplate_limit }}元可用
        </p>
      </div>

      <div class="title">
        <div class="t">
          <span>满减券</span>
          <h3 v-if="item.voucher.goods_ids.length == 0">全场通用券</h3>
          <h3 v-else>优惠抵用券</h3>
        </div>

        <p class="time">
          有效期至：{{ item.voucher.vouchertemplate_enddate | time }}
        </p>
        <p class="time" v-if="item.voucher.goods_ids.length != 0">
          仅限于:限品
        </p>
      </div>

      <div
        class="btn "
        :class="{
          btn_act: listClass == 2,
        }"
      >
        <div
          @click="
            $router.push({
              name: 'HomeIndex',
            })
          "
        >
          {{ listClass == 1 ? "使用" : "已领取" }}
        </div>
      </div>
    </template>

    <template v-if="item.type == 'goods'">
      <div class="icon">
        <svg-icon icon-class="card_shop"></svg-icon>
      </div>
      <div class="title">
        <div class="t">
          <span>商品券</span>
          <h3>{{ item.goods.goods_name }}</h3>
        </div>

        <p class="time">
          有效期至：{{
            item.receive_end_time
              ? item.receive_end_time
              : itemParent.receive_end_time | time
          }}
        </p>
        <p class="time">
          下单任意商品自动附带到订单中
        </p>
      </div>
      <div class="btn" v-if="item.status == 0">
        <div @click="GCSHOW = true">
          使用
        </div>
      </div>
      <div class="btn btn_act" v-else>
        <div
          @click="
            $router.push({
              name: 'Xiaoet_Order',
            })
          "
        >
          已使用
        </div>
      </div>
      <oGC :show.sync="GCSHOW" @contentKefu="contentKefu" :id="item.id"></oGC>
    </template>

    <template v-if="item.type == 'other'">
      <div class="icon">
        <svg-icon icon-class="card_gift"></svg-icon>
      </div>
      <template>
        <div class="title">
          <div class="t">
            <span>其他券</span>
            <h3>{{ item.name || "特殊产品" }}</h3>
          </div>

          <!-- <p class="time">有效期至：1970</p> -->
        </div>
      </template>

      <div class="btn">
        <div @click="goConetent">
          使用
        </div>
      </div>
    </template>

    <template v-if="item.type == 'lottery'">
      <div class="icon">
        <svg-icon icon-class="card_lo"></svg-icon>
      </div>
      <template>
        <div class="title">
          <div class="t">
            <span>抽奖券</span>
            <h3>抽奖</h3>
          </div>

          <p class="time">有效期至：{{ item.lottery.end_time | time }}</p>
        </div>
      </template>
      <div class="btn">
        <div
          @click="
            $router.push({ name: 'LuckDraw', query: { id: item.lottery.id } })
          "
        >
          使用
        </div>
      </div>
    </template>

    <template v-if="item.type == 'choose' || item.type == 'random'">
      <template v-if="!item.choosed.type">
        <div class="icon">
          <svg-icon icon-class="card_shop"></svg-icon>
        </div>
        <template>
          <div class="title">
            <div class="t">
              <span>挑选券</span>
              <h3>多选一</h3>
            </div>
            <!-- <p class="time">有效期至：{{ item.lottery.end_time }}</p> -->
          </div>
        </template>
        <div class="btn">
          <div @click="chooeseRadio(item)">
            选择
          </div>
        </div>
        <oSigned
          :show.sync="signedShow"
          :obj="thisSigninObj"
          :chooeseObj="thisChooeseItem"
          @enter="enterbtn"
        ></oSigned>
      </template>
      <template v-else>
        <oLogs
          :item="item.choosed"
          @use="goConetent"
          :listClass="2"
          :itemParent="item"
        ></oLogs>
      </template>
    </template>

    <!-- <div class="solid">
      <img class="line" src="@image/me/1634288786.png" />
    </div> -->
  </div>
</template>

<script>
import oSigned from "./signed.vue";
import oGC from "./goodsChooese.vue";
import { Toast } from "vant";
export default {
  name: "oLogs",
  props: {
    item: {},
    listClass: {
      default: 1,
      string: Number,
    },
    itemParent: {},
  },
  data() {
    return {
      GCSHOW: false,
      signedShow: false,
      thisSigninObj: {},
      thisChooeseItem: {},
    };
  },
  components: {
    oSigned,
    oGC,
  },
  methods: {
    goConetent() {
      console.log("//");
      this.$emit("use", true);
    },
    contentKefu() {
      // console.log("//");
      this.$emit("use", true);
    },
    enterbtn() {
      Toast("选择成功");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    chooeseRadio(item) {
      this.signedShow = true;
      this.thisSigninObj = item;
      console.log(item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.coupon_list-ul {
  .li {
    padding: 0 rem(32);
    background: #fdeef1;
    border: 1px solid #f2dce0;
    box-sizing: border-box;
    border-radius: rem(16);
    width: rem(690);
    margin: 0 auto rem(30);
    height: rem(148);
    display: flex;

    .wrap {
      display: flex;
      width: 100%;
      align-items: center;
      .icon {
        width: rem(128);

        margin-right: rem(46);
        svg {
          margin: 0 auto;
          display: block;
          width: rem(80);
          height: rem(80);
        }
      }
      .price {
        margin-bottom: rem(16);
        width: rem(150);
        margin-right: rem(24);
        color: #ff4848;
        .couponPrice {
          height: rem(70);
          text-align: center;
          span {
            font-size: rem(28);
          }
          b {
            font-weight: bold;
            font-size: rem(50);
          }
        }
        .couponSub {
          font-size: rem(22);
          @include ell;
        }
      }
      .title {
        margin-bottom: rem(16);
        flex: 1;
        .t {
          display: flex;
          align-items: center;
          height: rem(70);
          span {
            display: block;
            color: #ffffff;
            font-size: rem(20);
            height: rem(28);
            line-height: rem(28);
            padding: 0 rem(6);
            text-align: center;
            background: #fc7474;
            border-radius: rem(6);
            margin-right: rem(6);
          }
          h3 {
            color: #222222;
            font-weight: bold;
            font-size: rem(28);
            @include ell;
            width: rem(200);
          }
        }
        .time {
          color: #666666;
          font-size: rem(22);
        }
      }
      .solid {
        position: relative;
        width: rem(22);
        height: rem(148);
        .line {
          position: absolute;
          width: rem(22);
          height: rem(148);
          top: rem(-0);
          left: 0;
        }
      }

      .btn {
        width: rem(130);
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          background: #ffffff;
          width: rem(74);
          height: rem(44);
          border: 1px solid #ff4848;
          box-sizing: border-box;
          border-radius: rem(6);
          width: rem(74);
          height: rem(44);
          line-height: rem(44);
          text-align: center;
          color: #ff4848;
          font-size: rem(26);
          margin-left: rem(24);
        }
      }
      .btn_act {
        div {
          width: rem(100);
          color: #f2dce0;
          border: 1px solid #f2dce0;
          margin-left: rem(30);
        }
      }
    }
  }
}
</style>
