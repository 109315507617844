<template>
  <div style="margin-top:20px">
    <ul class="coupon_list-ul" v-if="oDataList && oDataList.length != 0">
      <li class="li" v-for="(item, index) in oDataList" :key="index">
        <oItem :item="item" @use="contentKefu"></oItem>
      </li>
    </ul>
    <oChat :boo.sync="kefuBoo"></oChat>
    <div style="text-align:center;color:grey">
      --暂无更多--
    </div>
  </div>
</template>

<script>
import { get, post } from "@get/http";
import oChat from "@/view/me/view/chat/popup.vue";
import oItem from "./components/logs.vue";

export default {
  name: "CardLog",
  components: {
    oItem,
    oChat,
  },
  data() {
    return {
      kefuBoo: false,
      oDataList: [],
    };
  },
  methods: {
    contentKefu() {
      this.kefuBoo = true;
    },
  },
  async created() {
    let list = await post("/lw.MemberWelfare/getSigninWelfare_v2", {
      data: {
        welfare_id: this.$route.query.signin_id,
      },
    });
    if (list) {
      // for (let index = 0; index < list.length; index++) {
      //   if (list[index].type == "choose") {
      //     list[index][list[index].choosed.type] = list[index].choosed;
      //   }
      // }

      this.oDataList = list;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.coupon_list-ul {
  .li {
    padding: 0 rem(32);
    background: #fdeef1;
    border: 1px solid #f2dce0;
    box-sizing: border-box;
    border-radius: rem(16);
    width: rem(690);
    margin: 0 auto rem(30);
    height: rem(148);
    display: flex;
  }
}
</style>
